(function(){"use strict";class p{constructor(){this.data={}}set(e,t){this.data[e]=t}get(e){return this.data[e]}}const m={Booster:class{constructor(e){this.options=e}log(e,t){const o=`
      display: inline-block;
      padding: 4px 6px;
      border-radius: 4px;
      line-height: 1.5em;
      color: #282735;
      background: linear-gradient(45deg,
        rgba(185, 205, 255, 0.4) 0%,
        rgba(201, 182, 255, 0.4) 33%,
        rgba(239, 184, 255, 0.4) 66%,
        rgba(255, 210, 177, 0.4) 100%);
        `,s=[`%c[${this.options.title}] ${e}. Link to documentation ${this.options.documentationLink}`,o];t?(console.group(...s),Array.isArray(t)?console.log(...t):console.log(t),console.groupEnd()):console.log(...s)}validate(e,t,o){if(!e.validate)return!0;if(typeof e.validate=="function"){if(!e.validate(o))return this.log(`Invalid value "${o}" for attribute "${t}"`),!1}else if(!e.validate.includes(o))return this.log(`Invalid value "${o}" for attribute "${t}"`,[`%cPossible values:%c
`+e.validate.map(s=>`• ${s}`).join(`
`),"font-weight: 700;","font-weight: initial;"]),!1;return!0}parse(e){const t=new p;for(const o in this.options.attributes){const s=this.options.attributes[o],l=e.getAttribute(o);if(!l){t.set(o,s.defaultValue);continue}if(!this.validate(s,o,l))continue;let n=l;s.parse&&(n=s.parse(l)??s.defaultValue),t.set(o,n)}this.options.apply.call(this,e,t)}getElements(){return document.querySelectorAll(`[${this.options.name}]`)}init(){this.getElements().forEach(t=>this.parse(t))}},parse:{stringToBoolean:i=>i!=="false"},validation:{isBoolean:i=>/^(true|false)$/.test(i),isNumber:i=>!isNaN(Number(i))}};var d=(i=>(i.Left="left",i.Right="right",i))(d||{});function A(i){const e=Array.from(i.querySelectorAll("[fb-slider-arrow]"));if(!e.length)return;const t=i.querySelector(".w-slider-arrow-left"),o=i.querySelector(".w-slider-arrow-right");if(!t&&!o)return this.log("Webflow Slider Arrows are not detected");const s=[],l=[];for(const n of e){const a=n.getAttribute("fb-slider-arrow"),u=a;Object.values(d).includes(u)?(u==="left"&&s.push(n),u==="right"&&l.push(n)):this.log(`Invalid value "${a}" for attribute "fb-slider-arrow"`,[`%cPossible values:%c
`+Object.values(d).map(f=>`• ${f}`).join(`
`),"font-weight: 700;","font-weight: initial;"])}if(s.length)if(t){t.style.display="none";for(const n of s)n.addEventListener("click",()=>t.click())}else this.log("Webflow Left Arrow isn't detected");if(l.length)if(o){o.style.display="none";for(const n of l)n.addEventListener("click",()=>o.click())}else this.log("Webflow Right Arrow isn't detected")}function y(i,e){var w;const t=i.querySelector("[fb-slider-nav=inactive]");if(!t)return this.log("Inactive Nav Element isn't detected");const o=i.querySelector("[fb-slider-nav=active]");if(!o)return this.log("Active Nav Element isn't detected");o.remove();const s=["aria-label","aria-pressed","role","tabindex"],l=(r,c)=>{for(const v of s){const g=r.attributes.getNamedItem(v);g&&c.setAttribute(g.name,g.value)}},n=r=>e[r].click(),a=[t];for(let r=0;r<e.length;r++){const c=t.cloneNode(!0);l(e[r],c),c.addEventListener("click",()=>n(r)),(w=a[r])==null||w.insertAdjacentElement("afterend",c),a.push(c)}t.remove(),a.shift();let u=-1,f;const b=()=>{u!==-1&&(a[u].replaceWith(f),a[u]=f);const r=e.find(v=>v.classList.contains("w-active"));if(!r)return;const c=e.indexOf(r);l(r,o),u=c,f=a[c],a[c].replaceWith(o),a[c]=o},E=new MutationObserver(b);for(const r of e)E.observe(r,{attributeFilter:["class"]});b()}const $=new m.Booster({name:"fb-slider",attributes:{},apply(i){A.call(this,i);const e=i.querySelector(".w-slider-nav");if(!e)return this.log("Webflow Slider Nav isn't detected");let t;const o=()=>{const s=Array.from(e.querySelectorAll(".w-slider-dot"));s.length?(y.call(this,i,s),t==null||t.disconnect()):t||(t=new MutationObserver(o),t.observe(e,{childList:!0}))};o()},title:"Webflow Custom Slider Booster",documentationLink:"https://www.flowbase.co/booster/webflow-custom-slider"}),h=()=>$.init();document.readyState==="complete"?h():window.addEventListener("load",h)})();
